
import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';

import {AppModule} from './app/app.module';
import {environment} from './environments/environment';
import {ConfigService} from './app/services/config/config.service';

if (environment.production) {
  enableProdMode();
}

(async () => {
  const config = await fetch("/assets/config/local.config.json");
  let data = await config.json();
  ConfigService.setConfig(data);

  const { AppModule } = await import("./app/app.module")
  try {
    platformBrowserDynamic().bootstrapModule(AppModule, {})
  } catch (error) {
    console.error(error)
  }
})()
