import {Injectable} from '@angular/core';
import {Config} from "./model/config";

@Injectable({
  providedIn: 'root'
})
export class ConfigService{

  static config = {} as Config;

  static setConfig(config: Config): void {
    this.config = config;
  }

  getConfig() {
    return ConfigService.config
  }

}
